import { flowRight, map } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import Slider from 'react-slick';
import withDirection, { withDirectionPropTypes } from 'react-with-direction';
import RecentPostsListItem from '../recent-posts-list-item';
import styles from './recent-post-carousel.scss';

const sliderProps = {
  arrows: false,
  className: styles.container,
  dots: false,
  useCSS: true,
  infinite: true,
  centerMode: true,
  centerPadding: '20px',
  slidesToShow: 1,
  slidesToScroll: 1,
};

const RecentPostCarousel = ({ direction, posts = [], isPostNumbersEnabled, handleLikeClick }) => (
  <div>
    <Slider {...sliderProps}>
      {map(posts, (post) => (
        <div className={styles.slide} key={post._id} dir={direction}>
          <RecentPostsListItem
            post={post}
            isPostNumbersEnabled={isPostNumbersEnabled}
            handleLikeClick={handleLikeClick}
            withCover={false}
          />
        </div>
      ))}
    </Slider>
  </div>
);

RecentPostCarousel.propTypes = {
  ...withDirectionPropTypes,
  posts: PropTypes.array.isRequired,
  isPostNumbersEnabled: PropTypes.bool.isRequired,
  handleLikeClick: PropTypes.func,
};

export default flowRight(withDirection)(RecentPostCarousel);

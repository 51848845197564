"use strict";

exports.__esModule = true;
exports.getUserRole = void 0;

var _lodash = require("lodash");

var _appsConfig = require("../constants/appsConfig");

const getUserRole = user => (0, _lodash.get)(user, "appStore[" + _appsConfig.FORUM_APP_ID + "].permissions.role") || (0, _lodash.get)(user, 'permissions.role');

exports.getUserRole = getUserRole;
import React from 'react';

const ViewsIcon = (props) => (
  <svg width="19" height="12" viewBox="0 0 19 12" {...props}>
    <path
      fillRule="nonzero"
      d="M18.654 6.96C17.395 8.47 13.98 11.98 9.464 12h-.038c-3.163 0-6.217-1.706-9.08-5.074a1.461 1.461 0 0 1 0-1.886C1.604 3.53 5.02.02 9.534 0h.04c3.162 0 6.217 1.706 9.08 5.074.46.545.46 1.342 0 1.886zm-.784-1.237C15.21 2.595 12.421 1.01 9.576 1.01h-.034c-4.08.019-7.235 3.273-8.401 4.67a.463.463 0 0 0-.008.6c2.659 3.128 5.45 4.712 8.294 4.712h.035c4.08-.018 7.234-3.272 8.401-4.67a.462.462 0 0 0 .007-.6zM9.5 9.893a3.898 3.898 0 0 1-3.848-3.93 3.898 3.898 0 0 1 3.918-3.86 3.898 3.898 0 0 1 3.884 3.895A3.928 3.928 0 0 1 9.5 9.893zm0-6.775a2.885 2.885 0 0 0-2.845 2.909 2.885 2.885 0 0 0 2.9 2.856A2.885 2.885 0 0 0 12.426 6a2.908 2.908 0 0 0-2.926-2.882z"
    />
  </svg>
);

export default ViewsIcon;

import React from 'react';

const CommentsIcon = (props) => (
  <svg width="15" height="14" viewBox="0 0 15 14" {...props}>
    <path
      fillRule="nonzero"
      d="M7.5 10.002H13a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v7.002a1 1 0 0 0 1 1h3v2.5l2.5-2.5zm5.508.974H8l-2.578 2.572C4.637 14.33 4 14.07 4 12.973v-1.997H1.992A2 2 0 0 1 0 8.98V1.995C0 .893.893 0 1.992 0h11.016A2 2 0 0 1 15 1.995V8.98a1.994 1.994 0 0 1-1.992 1.995z"
    />
  </svg>
);

export default CommentsIcon;
